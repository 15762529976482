.team-sign-up-grouped-element {
  margin: 5%;
  padding: 5%;
  border-radius: 10px;
  border: 1px solid white;
  color: #fff;
}

.team-sign-up-element {
  margin: 5%;
  padding: 2%;
  border-radius: 10px;
  border: 1px solid white;
  color: #fff;
}
