.info {
  margin: 4% 3% 3% 3%;
}

.name {
  display: block;
  font-size: 24px;
  font-weight: bold;
}

.role {
  display: block;
  margin: 1% 0 3% 0;
  font-size: 20px;
  font-style: italic;
}

.bio {
  font-size: 16px;
  margin: 3%;
}

.quote {
  font-size: 16px;
  margin: 3%;
}
