body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
    font-family: 'Cinzel';
    font-weight: 300;
    src: local('Cinzel'), url(./assets/fonts/cinzel/Cinzel-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Pacifico';
    font-weight: 100;
    src: local('Pacifico'), url(./assets/fonts/pacifico/Pacifico-Regular.ttf) format('truetype');
}
